#footer {
  color: #000000;
}

#footer a {
  color: #000000;
}

#footer div.main {
  background: #94dfe2;
  padding: 30px 0 20px 0;
}

#footer div.main div.follow {
  text-align: center;
  vertical-align: top;
}

#footer div.main div.follow a.link:not(:last-of-type) {
  margin: 0 10px 0 0;
}

#footer div.main div.links {
  margin: 20px 0 0 0;
  text-align: center;
  vertical-align: top;
}

#footer div.main div.links a {
  display: block;
}

#footer div.main div.sponsors {
  margin: 20px 0 0 0;
  text-align: center;
}

#footer div.main div.sponsors img {
  height: 40px;
}

#footer div.main div.sponsors a {
  text-decoration: none;
}

#footer span.title {
  display: block;
  font-size: 2em;
  padding: 0 0 10px 0;
}

#footer span.title span.info {
  display: block;
  font-size: 0.4em;
  vertical-align: middle;
}

#footer div.copyright_address {
  background: #53d6db;
  padding: 10px 0;
  text-align: center;
}

#footer div.copyright_address div.left {
  display: block;
  text-align: left;
}

#footer div.copyright_address div.right {
  display: block;
  text-align: right;
}

#footer div.copyright_address span.sep {
  display: none;
}

#footer div.ppl {
  margin: 30px 0 0 0;
}

#footer div.ppl img {
  display: block;
  height: 100px;
  margin: 0 auto;
}
