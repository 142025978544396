@import 'content-body';
@import 'footer';
@import 'schedule';

div.page {
  padding: 20px;
}

.container {
  width: 90%;
}

[ng-click] {
  cursor: pointer;
}
