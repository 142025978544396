#schedule div.days {
  display: block;
  text-align: center;
}

#schedule div.days a.day {
  background: #dddddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #555555;
  display: inline-block;
  font-size: 1em;
  padding: 5px 10px;
}

#schedule div.days a.day:nth-child(8) {
  display: none;
}

#schedule div.days a.day:hover {
  text-decoration: none;
}

#schedule div.days a.day.today {
  background: #53d6db;
}

#schedule div.line {
  background: #53d6db;
  height: 5px;
}

#schedule div.loading {
  display: block;
  margin: 20px 0;
  text-align: center;
}

#schedule div.shedule_item {
  background: #ffffff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 20px;
}

#schedule div.shedule_item div.image img {
  display: none;
}

#schedule div.shedule_item div.time {
  color: #53d6db;
}

#schedule div.shedule_item div.time span.now {
  font-weight: 700;
}

#schedule div.shedule_item div.time span.repeat {
  font-style: italic;
}

#schedule div.shedule_item div.name {
  font-size: 2em;
  font-weight: 700;
  margin: 10px 0 0 0;
}

#schedule div.shedule_item div.presenter {
  font-style: italic;
}

#schedule div.shedule_item div.show-info {
  margin: 20px 0 0 0;
}

#schedule div.history {
  display: none;
}
