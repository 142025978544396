#contact div.pres img {
  display: inline;
  margin: 0 10px 0 0;
  float: left;
  width: 100px;
}

#contact div.pres {
  margin-bottom: 20px;
}

#contact h2 {
  margin-top: 20px;
}