#homepage img {
  width: 100%;
}

#homepage div.dover-lotto-info {
  margin-top: 20px;
}

#homepage div.skill {
  margin: 0 0 10px 0;
}

#homepage div.skill img {
  float: left;
  width: 150px;
}

#homepage div.skill a.btn {
  margin: 10px 0 0 0;
  padding: 10px 40px;
}

#homepage div.coming-up {
  margin: 0 0 10px 0;
}

#homepage div.twitter {
  height: 550px !important;
  overflow-y: auto;
}

@media  (max-width: 1024px) {

  #homepage h1 {
    font-size: 2em;
  }

}

@media (max-width: 767px) {

  #homepage h3 {
    text-align: center;
  }

  #homepage h1 {
    font-size: 1.5em;
  }

  #homepage .section a {
    display: block;
    font-size: 1.4em;
  }

}