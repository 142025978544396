#privacyPolicy .firstList {
  counter-reset: chapter;
}

#privacyPolicy ol.clauses, #privacyPolicy ol.parties, #privacyPolicy ol.recitals {
  margin-left: 0;
  padding: 0 0 0 2em;
}

#privacyPolicy ol.clauses ol, #privacyPolicy ol.parties ol, #privacyPolicy ol.recitals ol {
  margin-left: 0;
  padding: 0 0 0 2em;
}

#privacyPolicy ol.clauses li, #privacyPolicy ol.parties li, #privacyPolicy ol.recitals li {
  font-size: 15px !important;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

#privacyPolicy ol.clauses > li > ol > li {
  list-style: lower-alpha !important;
}

#privacyPolicy ol.clauses > li > ol > li > ol > li {
  list-style: lower-roman;
}

#privacyPolicy ol.clauses > li, #privacyPolicy ol.parties > li {
  list-style-type: none;
  counter-increment: chapter;
}

#privacyPolicy ol.parties > li:before, #privacyPolicy ol.clauses > li:before {
  width: 2em;
  margin-left: -2em;
  display: inline-block
}

#privacyPolicy ol.clauses > li:before {
  content: counter(chapter) ". ";
}

#privacyPolicy ol.parties > li:before {
  content: "(" counter(chapter) ") ";
}

#privacyPolicy ol.recitals {
  list-style: upper-alpha !important;
}

#privacyPolicy ol.clausesNested, #privacyPolicy ol.clausesNested ol {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
}

#privacyPolicy ol.clausesNested {
  padding: 0 5em 0 5em;
}

#privacyPolicy ol.clausesNested li {
  font-weight: bold;
}

#privacyPolicy ol.clausesNested li p, #privacyPolicy ol.clausesNested li li {
  font-weight: normal;
}

#privacyPolicy ol.clausesNested li ol > li {
  margin-top: 1em;
  margin-bottom: 1em
}

#privacyPolicy ol.clausesNested li p {
  margin-top: 0;
}

#privacyPolicy ol.clausesNested > li, #privacyPolicy ol.clausesNested ol > li {
  display: block;
  position: relative;
}

#privacyPolicy ol.clausesNested > li:before, #privacyPolicy ol.clausesNested ol > li:before {
  display: inline-block;
  content: counters(item, ".") ". ";
  counter-increment: item;
  width: 4em;
  margin-left: -4em;
}

#privacyPolicy h1 {
  font-size: x-large !important;
  margin-top: 1em !important;
  margin-bottom: 1.5em !important;
  text-align: center !important;
  font-weight: bold !important;
}

#privacyPolicy h2 {
  font-size: 15px !important;
  margin-top: 2em !important;
  font-weight: bold !important;
}

#privacyPolicy h3 {
  font-weight: bold;
  font-size: 15px;
  margin-top: 2em;
}

#privacyPolicy table.definitions, #privacyPolicy div.schedule table {
  border-collapse: collapse !important;
  margin: 1em 0 2em -2em !important;
}

#privacyPolicy table.definitions tr:nth-child(odd), #privacyPolicy div.schedule table tr:nth-child(odd) {
  background-color: #eee;
}

#privacyPolicy table.definitions td, #privacyPolicy table.definitions th, #privacyPolicy div.schedule table td, #privacyPolicy div.schedule table th {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  padding: 5px !important;
  vertical-align: top !important;
  text-align: left !important;
}

#privacyPolicy table.definitions th, #privacyPolicy div.schedule table th {
  width: 25% !important;
}

#privacyPolicy table.signature, #privacyPolicy table.signature2, #privacyPolicy div.signature table {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

#privacyPolicy table.signature th, #privacyPolicy table.signature td, #privacyPolicy table.signature2 th, #privacyPolicy table.signature2 td, #privacyPolicy div.signature table th, #privacyPolicy div.signature table td {
  padding: 1em 0 0 0 !important;
}

#privacyPolicy table.signature th, #privacyPolicy table.signature2 th, #privacyPolicy div.signature table th {
  font-weight: normal !important;
  text-align: right !important;
  padding-right: 1em !important;
  min-width: 90px;
}

#privacyPolicy table.signature td.signatureBox, #privacyPolicy table.signature2 td.signatureBox, #privacyPolicy div.signature table td.signatureBox {
  border-bottom: 1px solid black !important;
  min-width: 300px;
}

#privacyPolicy .witness table th, #privacyPolicy .witness table td {
  border: none;
  vertical-align: text-top;
}

#privacyPolicy .witness table th {
  font-weight: normal;
}

#privacyPolicy ol.letterList {
  list-style: lower-alpha !important;
}

#privacyPolicy div.schedule {
  border-top: 1px solid black;
}

#privacyPolicy div.schedule h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1em;
}

#privacyPolicy div.schedule h3 {
  margin-top: 1em;
  text-align: center;
  margin-bottom: 3em;
}

#privacyPolicy table.withBorders {
  width: 100%;
  border-width: 0px;
  border-spacing: 0px;
  border-style: none;
  border-color: gray;
  border-collapse: collapse;
  background-color: white;
}

#privacyPolicy table.withBorders th {
  border-width: 1px;
  padding: 5px;
  border-style: solid;
  border-color: black;
  background-color: white;
  -moz-border-radius: ;
}

#privacyPolicy table.withBorders td {
  border-width: 1px;
  padding: 5px;
  border-style: solid;
  border-color: black;
  background-color: white;
  -moz-border-radius: ;
}

#privacyPolicy .pagebreakhere {
     page-break-before: always;
}
