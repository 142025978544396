#footer {
  color: #000000;
}

#footer a {
  color: #000000;
}

#footer div.main {
  background: #94dfe2;
  padding: 30px 0 20px 0;
}

#footer div.main div.follow {
  vertical-align: top;
}

#footer div.main div.follow a.link:not(:last-of-type) {
  margin: 0 10px 0 0;
}

#footer div.main div.links {
  vertical-align: top;
}

#footer div.main div.links a {
  display: table;
  margin: 10px 0;
}

#footer div.main div.sponsors img {
  height: 80px;
  margin-top: 5px;
}

#footer div.main div.sponsors a {
  text-decoration: none;
}

#footer span.title {
  display: block;
  font-size: 2em;
  padding: 0 0 20px 0;
}

#footer span.title span.info {
  font-size: 0.5em;
  vertical-align: middle;
}

#footer div.copyright_address {
  background: #53d6db;
  padding: 30px 0;
}

#footer div.copyright_address div.left {
  display: inline-block;
  text-align: left;
}

#footer div.copyright_address div.right {
  display: inline-block;
  float: right;
  text-align: right;
}

#footer div.copyright_address span.sep {
  color: rgba(0, 0, 0, 0.3);
}

#footer div.ppl {
  margin: 50px 0 0 0;
}

#footer div.ppl img {
  display: block;
  height: 100px;
  margin: 0 auto;
}
