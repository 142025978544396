#covid19 div.card-columns.items {
  margin-top: 10px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}

#covid19 a.link {
  color: #000000;
}

#covid19 a.link:hover {
  text-decoration: none;
}

@media  (max-width: 1610px) {

  #covid19 div.card-columns.items {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }

}


@media  (max-width: 1024px) {

  #covid19 div.card-columns.items {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

}

@media  (max-width: 767px) {

  #covid19 div.card-columns.items {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

}