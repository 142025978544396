@import '../../../../node_modules/lightbox2/src/css/lightbox.css';

@import 'header';
@import 'nav';
@import 'homepage';
@import 'sponsors';
@import 'presenters';
@import 'contact';

@import 'desktop/index' (min-width: 1025px);
@import 'tablet/index' (min-width: 768px) and (max-width: 1024px);
@import 'mobile/index' (max-width: 767px);

@import 'privacy-policy';
@import 'cookieConsent';
@import 'player';
@import 'covid19';

body {
  font-family: 'Roboto', sans-serif;
}

[ng-click] {
  cursor: hand;
}

div.page {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 2px 1px rgba(0,0,0,.1);
}

div.page:not(:last-of-type) {
  margin: 0 0 30px 0;
}

.sub-titles {
  margin: 10px 0;
}

#websiteUpdate {
  background: #ffcc80;
  box-shadow: 0 0 2px 1px rgba(0,0,0,.1);
  margin: 0 0 20px 0;
  padding: 10px 0;
  text-align: center;
}

.highlight {
  background: #ffcc80 !important;
}