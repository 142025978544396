#presenterList {
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
}

#presenterList img {
  width: 100%;
}

#presenterList div.presenter {
  background: #FFFFFF;
  color: #000000;
}

#presenterList div.presenter div.name {
  margin-top: 10px;
}

#presenter {
  background: #FFFFFF;
  padding: 20px;
}

#presenter img {
  width: 100%;
}

#presenter div.name {
  font-size: 2em;
  font-weight: bold;
}

#presenter div.bio {
  margin: 20px 0 0 0;
}

#shows {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}

@media (max-width: 1024px) {

  #presenterList {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }

}

@media (max-width: 767px) {

  #presenterList {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

}