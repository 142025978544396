#header {
  background: #94dfe2;
  // background: linear-gradient(180deg, #005bbb 50%, #ffd500 50%);
  // background: #111;
  // background-image: url('https://images.whitecliffsradio.co.uk/uj-black.webp');
  // background-size: 100% 300px;
  // background-repeat: none;
  // background-position: center center;
  color: #000000;
}

#header div.top {
  height: 200px;
}

#header img {
  width: 100%;
}

#header div.logo {
  background: #94dfe2;
  padding: 30px;
}

#header div.show-info {
  position: relative;
}

#header show {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 120px;
  background: #95dfe4;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#header show img {
  display: inline-block;
  margin: 0 10px 0 0;
}

@media (max-width: 767px) {

  #header div.top {
    height: 120px;
  }

}

@media (max-width: 1024px) {

  #header show {
    position: absolute;
    top: 30px;
    bottom: 0;
    left: 20px;
    right: 0;
    margin: auto;
    height: 100px;
  }

}