@import 'content-body';
@import 'footer';
@import 'schedule';

div.page {
  padding: 10px;
}

.container {
  width: 100%;
}

[ng-click] {
  cursor: pointer;
}
