#nav {
  background: #53d6db;
  color: #000000;
  position: sticky;
  top: 0;
  z-index: 999;
}

#nav div.presenterMenu div.item a {
  border-bottom: solid 3px #DDDDDD;
  color: #000000;
  display: block;
  margin: 0 5px;
  padding: 10px;
  text-align: center;
}

#nav div.presenterMenu div.item a:hover {
  text-decoration: none;
  border-bottom: solid 3px #666666;
}

#nav li.nav-item {
  margin-bottom: -9px;
  margin-top: -9px;
}

#nav li.nav-item.active {
  background: #1fc2c8;
}

#nav a.nav-link {
  color: #000000;
  display: inline-block;
  line-height: 14px;
  padding: 20px 30px;
}

#nav li.nav-item:hover {
  background: #1fc2c8;
}

@media (max-width: 1024px) {

  #nav li.nav-item {
    margin-bottom: inherit;
    margin-top: inherit;
  }

  #nav div.presenterMenu {
    width: 500px;
  }

}

@media (min-width: 1025px) {

  #nav div.presenterMenu {
    width: 800px;
  }

}
